import Pristine from 'pristinejs'
import axios from 'axios'

const CONTACT_FORM_API_URL =
  'https://2tsn8buh2c.execute-api.us-east-1.amazonaws.com/prod/form-submit'

/*
 * Smooth Scroll
 */
window.scroll({
  top: 2500,
  left: 0,
  behavior: 'smooth'
})
window.scrollBy({
  top: 1,
  left: 0,
  behavior: 'smooth'
})

/*
 * Contact form
 */
const formWrapper = document.querySelector('#contact')
if (formWrapper) {
  const form = formWrapper.querySelector('.contact-form')
  const messageSentInfo = formWrapper.querySelector('.info--success')
  const messageNotSentInfo = formWrapper.querySelector('.info--error')
  const messageSubmittingInfo = formWrapper.querySelector('.info--submitting')
  const timesEls = formWrapper.querySelectorAll('.times input')
  const protocolsEls = formWrapper.querySelectorAll('.protocols input')

  const changeTimes = () => {
    const isMorningChecked = formWrapper.querySelector('[name="callMeMorning"]').checked
    const isAfternoonChecked = formWrapper.querySelector('[name="callMeAfternoon"]').checked
    const isNightChecked = formWrapper.querySelector('[name="callMeNight"]').checked
    formWrapper.querySelector('[name="times"]').checked =
      isMorningChecked || isAfternoonChecked || isNightChecked

    pristine.validate()
  }

  const changeProtocols = () => {
    const isVitalityChecked = formWrapper.querySelector('[name="productVitality"]').checked
    const isPerformanceChecked = formWrapper.querySelector('[name="productPerformance"]').checked
    const isLongevityChecked = formWrapper.querySelector('[name="productLongevity"]').checked
    formWrapper.querySelector('[name="protocols"]').checked =
      isVitalityChecked || isPerformanceChecked || isLongevityChecked

    pristine.validate()
  }

  timesEls.forEach(el => el.addEventListener('change', changeTimes))
  protocolsEls.forEach(el => el.addEventListener('change', changeProtocols))

  const pristine = new Pristine(form)

  form.addEventListener('submit', e => {
    e.preventDefault()

    if (!pristine.validate()) return

    if (form.classList.contains('contact-form--submitting')) return

    const formData = new FormData(form)
    const messageFormData = new URLSearchParams()

    for (const [key, value] of formData.entries()) {
      messageFormData.append(key, value)
    }

    form.classList.add('contact-form--submitting')
    messageSubmittingInfo.classList.add('info--active')
    form.querySelector('button').setAttribute('disabled', true)

    window.grecaptcha
      .execute('6LdYxasUAAAAAIB7n1vdayKW6XAnIP1Zmqak3SEm', { action: 'EMAIL' })
      .then(token => {
        messageFormData.append('captchaResponse', token)

        const axiosConfig = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }

        axios
          .post(CONTACT_FORM_API_URL, messageFormData, axiosConfig)
          .then(({ data, status }) => {
            if (status === 200) {
              messageSentInfo.classList.add('info--active')
              window.fbq('track', 'Lead')
            } else {
              messageNotSentInfo.classList.add('info--active')
              console.warn('error', data)
            }
          })
          .catch(error => {
            messageNotSentInfo.classList.add('info--active')
            console.warn('error', error)
          })
          .then(() => {
            form.classList.add('contact-form--submitted')
            messageSubmittingInfo.classList.remove('info--active')
          })
      })
  })
}
